<template>
  <v-app>
    <v-main class="page">
      <v-container fluid>
        <LoadingParcel v-if="isLoading"></LoadingParcel>

        <transition>
          <router-view
            v-show="!isLoading"
            ref="PageView"
            class="full-height"
          ></router-view>
        </transition>
      </v-container>
    </v-main>

    <LateralMenu v-if="false && !isLoading"></LateralMenu>
  </v-app>
</template>

<script>
import EventBus from '@/mixins/EventBus/index'
import Locale from '@/mixins/Locale/index'
import Login from '@/mixins/Login/index'
import NavigationGuard from '@/mixins/NavigationGuard/index'
import Sleek from '@/mixins/Sleek'
import Unhead from '@/mixins/Unhead'

import LateralMenu from '@/views/LateralMenu/LateralMenu.vue'
import LoadingParcel from '@/views/Login/Partials/Loading.vue'

export default {
  name: 'App',

  components: {
    LoadingParcel,
    LateralMenu,
  },

  mixins: [EventBus, Unhead, NavigationGuard, Locale, Sleek, Login],

  data() {
    return {
      isLoading: true,
    }
  },

  methods: {
    exitLoadingStage() {
      this.isLoading = false
    },

    hookGuardSuccess_loading() {
      this.exitLoadingStage()
    },

    hookLoginSuccess_loading() {
      this.exitLoadingStage()
    },
    hookUserNotFound_loading() {
      this.exitLoadingStage()
    },
  },
}
</script>
