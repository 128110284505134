<template>
  <LateralMenu v-if="!_blockLateralMenu"></LateralMenu>
</template>

<script>
export default {
  name: 'LateralMenuView',
  data() {
    return {}
  },
  computed: {
    _currentContext() {
      const routeName = this.$route.name
      const contexts = {}

      return contexts[routeName] || ''
    },
    _blockLateralMenu() {
      const blockedContexts = []

      return blockedContexts.includes(this._currentContext)
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
